<template>
  <div :class="bgColor" class="fixed inset-x-0 bottom-safe-0 z-20 select-none">
    <div class="footer-wrapper md:p-2">
      <div v-if="state === 'recalling'">
        <button class="button bg-blue-750"
                @click="$emit('rotate')">Перевернуть</button>
      </div>
      <div v-else-if="state === 'testing'">
        <button class="button bg-yellow-600"
                @click="$emit('check')">Проверить</button>
      </div>
      <div v-else-if="state === 'summarizing'">
        <div class="h-full flex justify-center md:w-3/4 md:mx-auto">
          <button class="button bg-red-700 md:mr-2"
                  @click="$emit('forgot')">
            Не помню</button>
          <button class="button"
                  :class="testFailed ? 'bg-green-300' : 'bg-green-700'"
                  :disabled="testFailed"
                  @click="$emit('recalled')">
            Помню</button>
        </div>
      </div>
      <div v-else-if="state === 'answering'">
        <button class="button bg-green-700"
                @click="$emit('answer')">Ответить</button>
      </div>
      <div v-else-if="state === 'reflecting'">
        <button class="button bg-blue-750"
                @click="$emit('next')">Вперед</button>
      </div>
      <div v-else-if="state === 'launching'">
        <button :class="data.count > 0 ? '' : 'opacity-50'"
                class="button bg-green-600"
                @click="$emit('start')">Начать</button>
      </div>
      <div v-else-if="state === 'loading'">
        <div class="h-full w-full flex justify-center items-center rounded bg-blue-400 text-white text-2xl">
          <div class="loader"></div>
        </div>
      </div>
      <div v-else-if="state === 'finished'">
        <router-link :to="{name: 'PageDashboard'}"
                     class="h-full flex justify-center">
          <button class="button bg-blue-850">
            Главное меню</button>
        </router-link>
      </div>
    </div>
    <div v-if="state === 'answering' && data.taskKind !== 'term'"
         class="keyboard-wrapper bg-gray-300 rounded-lg grid sm:hidden select-none">
      <button v-for="val in ['1','2','3','4','5','6','7','8']"
              :key="val"
              :class="keyboardRoundness(val)"
              class="bg-gray-50 w-full h-full"
              @click="$emit('key', val)">{{ val }}</button>
      <button class="bg-gray-50 w-full h-full"
              @click="$emit('key', 'bs')">
        <font-awesome-icon icon="backspace"></font-awesome-icon>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    state: String,
    data: Object,
  },
  data () {
    return {

    };
  },
  computed: {
    ...mapState({
      testFailed: state => state.flashes.testFailed,
    }),
    bgColor () { return this.state === 'launching' ? 'bg-blue-750' : 'bg-white'; },
  },
  methods: {
    keyboardRoundness (val) {
      if (val === '1') return 'rounded-tl-lg';
      if (val === '7') return 'rounded-bl-lg';
    }
  }
}
</script>

<style scoped lang="scss">
.bottom-safe-0 {
  bottom: 0;

  @media (max-width: 639px) {
    height: calc(4rem + 0.6 * env(safe-area-inset-bottom));
  }

  @media (min-width: 640px) {
    height: 5rem;
  }
}

.keyboard-wrapper {
  position: absolute;
  right: 0;
  width: 45vw;
  height: 45vw;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 1px;
  align-items: center;
  justify-items: center;

  transform: translate(0, calc(-4rem - 0.6 * env(safe-area-inset-bottom) - 45vw - 0.5rem));
}

.footer-wrapper {
  display: grid;
  margin: auto;
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-gap: 0;

  @media (max-width: 639px) {
    width: 100%;
  }
  @media (min-width: 640px) {
    width: 75%;
  }
  @media (min-width: 1024px) {
    width: 50%;
  }
}

.button {
  @apply w-full h-full md:rounded text-2xl text-white font-light;
}

.loader {
  border: 4px solid #2B6CB0;
  border-top: 4px solid #FFFFFF;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  animation: spin 0.5s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
