<template>
  <div>
    <div v-for="block in blocks" class="mb-2">
      <block-viewer v-if="block.blockType === 'text'"
                    :side="side"
                    :block="block"
                    :key="block.key"/>
      <img v-else :src="block.content"
           class="block mx-auto"
           style="max-height: 50vh;"
           :key="block.key">
    </div>
  </div>
</template>

<script>
import BlockViewer from '@/components/BlockViewer.vue';

export default {
  components: { BlockViewer },
  props: {
    side: String,
    blocks: Array,
  },
  data () {
    return {

    };
  },
}
</script>

<style scoped>

</style>
