<template>
  <div>
    <div v-for="sb in subblocks"
         :key="sb.key"
         class="inline">
      <span v-if="!isTesting || side === 'question'"
            :class="sb.decoration"
            class="whitespace-pre-wrap">{{ sb.content }}</span>
      <span v-else-if="sb.isHiddenBlock"
            :class="sb.decoration"
            class="whitespace-pre-wrap">{{ currentStep === 'summarizing' ? sb.hiddenContent : sb.content }}</span>
      <span v-else-if="!sb.isTestingBlock"
            :class="sb.decoration"
            class="whitespace-pre-wrap">{{ sb.content }}</span>
      <input v-else-if="currentStep === 'testing'"
             v-model="sb.value"
             class="w-32 mb-1 px-1 h-8 bg-blue-50 rounded border-b-2 border-blue-800"
             type="text"
             maxlength="140">
      <span v-else
            :class="isContentCorrect(sb.content, sb.value) ? 'text-green-600' : 'text-red-600'"
            class="whitespace-pre-wrap">{{ sb.content }}</span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { nanoid } from 'nanoid';

export default {
  props: {
    side: String,
    block: Object,
  },
  data () {
    return {
      subblocks: [],
    };
  },
  computed: {
    ...mapState({
      isTesting: state => state?.flashes?.isTesting,
      currentStep: state => state.flashes.currentStep,
    }),
  },
  watch: {
    currentStep (newStep, oldStep) {
      if (oldStep === 'testing'){
        const isFailed = this.subblocks
            .filter(sb => sb.isTestingBlock)
            .some(sb => !this.isContentCorrect(sb.content, sb.value));
        this.$store.commit('flashes/setFailedStatus', isFailed);
      }
    },
  },
  created () {
    this.subblocks = this.block.content
        .split(/(\*\*.+?\*\*|__.+?__|==.+?\s|%%.+?\s)/)
        .filter(spl => spl)
        .map((spl) => {
          const key = nanoid(9);
          const isTestingBlock = /__|\*\*/.test(spl);
          const isHiddenBlock = /\[\[.+?]]/.test(spl);

          let content = spl.replace(/\*\*|__|==|%%/g, '');
          let hiddenContent = '';
          if (this.isTesting && isHiddenBlock) {
            hiddenContent = content.replace(/\[\[/g, '(').replace(/]]/g, ')');
            content = content.replace(/\[\[.+?]]/g, '(...)');
          }
          else content = content.replace(/\[\[/g, '(').replace(/]]/g, ')');

          const value = isTestingBlock ? '' : content;
          const decoration = /==/.test(spl) ? 'font-bold' : (/%%/.test(spl) ? 'italic' : '');
          return { key, isTestingBlock, isHiddenBlock, content, hiddenContent, value, decoration };
        });
  },
  methods: {
    unify (str) {
      return str.toLowerCase()
          .replace(/\s/g, '')
          .replace('ё', 'е')
          .replace(/¹|₁/g, '1')
          .replace(/²|₂/g, '2')
          .replace(/³|₃/g, '3')
          .replace(/⁴|₄/g, '4')
          .replace(/⁵|₅/g, '5')
          .replace(/⁶|₆/g, '6')
          .replace(/⁷|₇/g, '7')
          .replace(/⁸|₈/g, '8')
          .replace(/⁹|₉/g, '9')
          .replace(/⁰|₀/g, '0')
          .replace(/⁺|₊/g, '+')
          .replace(/⁻|₋/g, '-')
          .replace(/⋅|•/g, '*')
          .split('');
    },
    isContentCorrect (content, value) {
      const uniContent = this.unify(content);
      const uniValue = this.unify(value);
      const lengthDiff = Math.abs(uniContent.length - uniValue.length);

      if (uniContent.length <= 6) {
        // console.log(`Fail status for block "${content}": ${uniContent.join('') === uniValue.join('')}`)
        return uniContent.join('') === uniValue.join('');
      }

      const isTypoed = lengthDiff <= 1 && uniContent
          .filter(x => !uniValue.includes(x))
          .concat(uniValue.filter(x => !uniContent.includes(x)))
          .length <= 2;

      // console.log(`Fail status for block "${content}": ${isTypoed}`)

      return isTypoed;
    },
  },
}
</script>

<style scoped>

</style>
